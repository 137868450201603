import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { ThemeService } from '../../../@prep/services/theme.service';
import { Router } from '@angular/router';
import { DashboardService } from '../../pages/dashboard/service/dashboard.service';
// Freshwork Service for Help widget Open / Close
import { NgxFreshworkWidgetService } from '../../widget/ngx-freshwork-widget/ngx-freshwork-widget.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PlatformGlobalService } from '../service/platform-global.service';
import { Subscription } from 'rxjs';
import { CurrentUserService } from '../../pages/authentication/service/current-user.service';

@Component({
  selector: 'prep-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [DashboardService],
})
export class ToolbarComponent implements OnInit {
  videourl: string;
  videobool = false;
  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;
  helpWidgetBool = false;
  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();
  account_user_name: string;
  isSuperAdmin = false;

  dashboardUserFilesSub: Subscription;

  topNavigation$ = this.themeService.config$.pipe(
    map((config) => config.navigation === 'top')
  );

  constructor(
    private themeService: ThemeService,
    private freeDeskWidgetService: NgxFreshworkWidgetService,
    private dashboardService: DashboardService,
    private snackbar: MatSnackBar,
    private currentUserService: CurrentUserService,
    private platformGlobalService: PlatformGlobalService,
    public router: Router
  ) {}

  ngOnInit() {
    // Authorize
    this.currentUserService.GetCurrentUser().subscribe(
      (response) => {
        this.platformGlobalService.me = response;
      },
      (error) => {
        console.log('error', error);
      }
    );
    console.log('myinfo', this.platformGlobalService.myUserInfo);
    this.account_user_name =
      this.platformGlobalService.myUserInfo.first_name +
      ' ' +
      this.platformGlobalService.myUserInfo.last_name;
       this.isSuperAdmin = this.platformGlobalService.myUserInfo.is_superuser
  }

  clickGoToTraning() {
    console.log(
      'item values',
      this.platformGlobalService.getStoreDashboardInfo
    );
    const urlToken = this.platformGlobalService.getStoreDashboardInfo.url_token;
    if (urlToken !== '') {
      this.videourl = urlToken;
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.videourl);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      this.snackbar.open(
        'Training video not Available for this user',
        'OK THANKS',
        {
          duration: 10000,
        }
      );
    }
  }

  HelpClick() {
    this.freeDeskWidgetService.FreshworksWidget('hide');
    setTimeout(() => {
      this.freeDeskWidgetService.FreshworksWidget('open');
    }, 500);
    // this.helpWidgetBool = !this.helpWidgetBool;
    // if (this.helpWidgetBool) {
    //   this.freeDeskWidgetService.FreshworksWidget('open');
    // } else {
    //   this.freeDeskWidgetService.FreshworksWidget('hide');
    // }
  }
}
